import React, { useEffect, useState, useMemo } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { shallowEqual, useSelector } from 'react-redux'
import { KTSVG } from '../../../../_metronic/helpers'
import { SelectDropDown } from '../../../components/SelectDropDown'
import * as helper from '../../../../helper'
import { UserModel, CustomerModel } from '../../../../types'
import { RootState } from '../../../../setup'
import { CustomDropDown } from '../../../pages/customers/component/CustomDropDown'
import { getCustomersByOrderId } from '../../../services/service'
import { STATES } from '../../../../data/constants'
import toast from 'react-hot-toast'

type Props = {
  show: boolean
  onHide: any
  title: string
  invalidMessage: string
  quoteId: number
  option: any
  cardName: string | undefined
  customer: CustomerModel | undefined
  sCustomer: UserModel | undefined
  submit: boolean
  onCreate: any
}

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  contactFirstName: Yup.string().required('First name is required'),
  contactLastName: Yup.string().required('Last name is required'),
  billingCompanyName: Yup.string().required('Company name is required'),
  billAddress: Yup.string().required('Address is required'),
  billCity: Yup.string().required('City is required'),
  billState: Yup.string().required('State is required'),
  billPhoneNumber: Yup.string().required('Phone number is required'),
  billZip: Yup.string().required('Zip code is required'),
})

export const AddCardModal: React.FC<Props> = (props) => {
  const {
    show,
    onHide,
    title,
    invalidMessage,
    quoteId,
    option,
    cardName,
    customer,
    sCustomer,
    submit,
    onCreate,
  } = props
  const [isAdd, setIsAdd] = useState<boolean>(true)
  const [cardType, setCardType] = useState<string>('')
  const [maskedCardNumber, setMaskedCardNumber] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [billToggle, setBillToggle] = useState<boolean>(false)
  const [isSave, setIsSave] = useState<boolean>(true)
  const [billState, setBillState] = useState<string>('')
  const [selectedCustomer, setSelectedCustomer] = useState<UserModel>()
  const [customers, setCustomers] = useState<UserModel[]>([])

  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const initialValues = useMemo(() => {
    return {
      cardName: title === 'Add New Card' ? '' : cardName ?? '',
      cardNumber:
        title === 'Add New Card' ? '' : option?.profile?.payment?.creditCard?.cardNumber ?? '',
      month:
        title === 'Add New Card'
          ? ''
          : option?.profile?.payment?.creditCard?.expirationDate?.split('-')[1] ?? 'Month',
      year:
        title === 'Add New Card'
          ? ''
          : option?.profile?.payment?.creditCard?.expirationDate?.split('-')[0] ?? 'Year',
      cvv: '',
      profileId: title === 'Add New Card' ? '' : option?.profile?.customerPaymentProfileId ?? '',
      companyId: customer?.companyId ?? '',
      taxExempt: customer?.taxExempt === 1 ? 'Yes' : 'No',
      status: customer?.status ?? '',
      contactFirstName: customer?.contactFirstName ?? '',
      contactLastName: customer?.contactLastName ?? '',
      billingCompanyName: customer?.billingCompanyName ?? '',
      billContact: customer?.billContact ?? '',
      billPhoneNumber: customer?.billPhoneNumber ?? '',
      billFaxNumber: customer?.billFaxNumber ?? '',
      billAddress: customer?.billAddress ?? '',
      billCity: customer?.billCity ?? '',
      billState: customer?.billState ?? '',
      billZip: customer?.billZip ?? '',
    }
  }, [customer, option, cardName, title])

  const handleSubmit = (values: any) => {
    if (isAdmin && customers.length === 0) {
      toast.error('You can not update payment option because there is not assigned customer.')
      return
    }

    if (isAdmin && !selectedCustomer) {
      toast.error('Select a customer')
      return
    }

    onCreate({
      isAdd: isAdd,
      isSave: isSave,
      quoteId: quoteId,
      customerId: selectedCustomer ? selectedCustomer.id : 0,
      ...values,
    })
  }

  const handleSelctCustomer = (value: string | number) => {
    let sCustomer = customers.find(
      (c) =>
        c.firstname === value.toString().split(' ')[0] &&
        c.lastname === value.toString().split(' ')[1]
    )
    setSelectedCustomer(sCustomer)
  }

  const validateCardName = (value: string) => {
    let error
    if (!value) {
      error = 'Name of card is required'
    } else {
      const result = helper.cardNameValidator(value)

      if (!result.isValid) error = 'Invalid name of card'
    }
    return error
  }

  const validateCardNumber = (value: string) => {
    let error

    if (!isAdd) return error

    if (!value) {
      error = 'Card number is required'
    } else {
      const result = helper.cardNumberValidator(value)

      setCardType(result.cardType)

      if (!result.isValid) error = 'Invalid card number'
    }
    return error
  }

  const validateMonth = (value: string) => {
    let error
    if (!value) {
      error = 'Expiration Month is required'
    } else {
      const result = helper.cardMonthValidator(value)

      if (!result.isValid) error = 'Invalid Expiration month'
    }
    return error
  }

  const validateYear = (value: number) => {
    let error
    if (!value) {
      error = 'Expiration Year is required'
    }
    // else {
    //   const result = helper.cardYearValidator(value)

    //   if (!result.isValid) error = 'Invalid Expiration year'
    // }
    return error
  }

  const validateCVV = (value: string) => {
    let error

    if (!isAdd) return error

    if (!value) {
      error = 'CVV on Card is required'
    } else {
      const result = helper.cardCVVValidator(value)

      if (!result.isValid) error = 'Invalid CVV on Card'
    }
    return error
  }

  useEffect(() => {
    if (quoteId !== 0) {
      getCustomersByOrderId(quoteId).then((res) => {
        setCustomers(res.data)

        if (res.data.length !== 0) {
          if (sCustomer && sCustomer?.id) {
            setSelectedCustomer(sCustomer)
          } else {
            setSelectedCustomer(res.data[0])
          }
        }
      })
    }
  }, [quoteId, sCustomer])

  useEffect(() => {
    const addOrEdit = title === 'Add New Card' ? true : false

    setIsAdd(addOrEdit)

    if (addOrEdit) {
      setCardType('')
      setMaskedCardNumber('')
    }
  }, [title])

  useEffect(() => {
    setIsAdmin(helper.isNBS(user?.type))
  }, [user])

  useEffect(() => {
    setBillState(initialValues.billState)
  }, [initialValues])

  return (
    <Modal
      size='lg'
      show={show}
      onHide={onHide}
      dialogClassName='w-50'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>{title}</h2>
            <span className='text-hover-primary cursor-pointer' onClick={onHide}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <Formik
          validationSchema={CustomerEditSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, setFieldValue, errors, touched, values }) => (
            <Form>
              <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
                <div className='row mb-7'>
                  <div className={`col-lg-6 ${!isAdmin && 'd-none'}`}>
                    <label className='fw-bold fs-6 mb-2'>
                      <span className='required'>Select customer</span>
                    </label>
                    <SelectDropDown
                      data={customers.map((c) => c.firstname + ' ' + c.lastname)}
                      defaultValue={
                        selectedCustomer
                          ? selectedCustomer.firstname + ' ' + selectedCustomer.lastname
                          : 'No customer assigned'
                      }
                      dirUp={false}
                      selectedValue={(value: string | number) => handleSelctCustomer(value)}
                    />
                  </div>
                </div>
                <div className='fv-row mb-7'>
                  <label className='fw-bold fs-6 mb-2'>
                    <span className='required'>Name Of Card</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify a name of card holder'
                    ></i>
                  </label>
                  <Field
                    as='input'
                    className='form-control form-control-solid'
                    placeholder='card name'
                    name='cardName'
                    validate={validateCardName}
                  />
                  {touched.cardName && errors.cardName ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.cardName}</div>
                    </div>
                  ) : null}
                </div>
                <div className='fv-row mb-7'>
                  <div>
                    <label className='fw-bold fs-6 mb-2 pt-1 required'>Card Number</label>
                    {!isAdd && <span> - {maskedCardNumber}</span>}
                  </div>
                  <div className='position-relative'>
                    <Field
                      as='input'
                      className='form-control form-control-solid'
                      placeholder='Enter card number'
                      name='cardNumber'
                      disabled={!isAdd ? true : false}
                      validate={validateCardNumber}
                    />
                    <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                      <img
                        src='/media/icons/duotone/Shopping/visa.svg'
                        className={`h-25px ${cardType === 'visa' && 'border border-dark'}`}
                        alt='visa'
                      ></img>
                      <img
                        src='/media/icons/duotone/Shopping/mastercard.svg'
                        className={`h-25px ${cardType === 'mastercard' && 'border border-dark'}`}
                        alt='master'
                      ></img>
                      <img
                        src='/media/icons/duotone/Shopping/american-express.svg'
                        className={`h-25px ${
                          cardType === 'american-express' && 'border border-dark'
                        }`}
                        alt='american'
                      ></img>
                    </div>
                  </div>
                  {touched.cardNumber && errors.cardNumber ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.cardNumber}</div>
                    </div>
                  ) : null}
                </div>
                <div className='row mb-10'>
                  <div className='col-md-8 fv-row'>
                    <label className='required fs-6 fw-bold form-label mb-2'>Expiration Date</label>
                    <div className='row fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid'>
                      <div className='col-6'>
                        <SelectDropDown
                          data={months}
                          defaultValue={initialValues.month}
                          dirUp={true}
                          selectedValue={(value: string | number) => setFieldValue('month', value)}
                        />
                        <Field
                          as='input'
                          name='month'
                          className='form-control form-control-solid mb-3 d-none'
                          validate={validateMonth}
                        />
                        {touched.month && errors.month ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.month}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='col-6'>
                        <SelectDropDown
                          data={years}
                          defaultValue={initialValues.year}
                          dirUp={true}
                          selectedValue={(value: string | number) => setFieldValue('year', value)}
                        />
                        <Field
                          as='input'
                          name='year'
                          className='form-control form-control-solid mb-3 d-none'
                          validate={validateYear}
                        />
                        {touched.year && errors.year ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.year}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 fv-row fv-plugins-icon-container'>
                    <label className='fs-6 fw-bold form-label mb-2'>
                      <span className='required'>CVV</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Enter a card CVV code'
                      ></i>
                    </label>
                    <div className='position-relative'>
                      <Field
                        as='input'
                        name='cvv'
                        validate={validateCVV}
                        className='form-control form-control-solid'
                        minLength={3}
                        maxLength={4}
                        placeholder='CVV'
                        disabled={!isAdd ? true : false}
                      />
                      <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                        <KTSVG
                          path='/media/icons/duotone/Shopping/fin002.svg'
                          className='svg-icon-2hx'
                        />
                      </div>
                    </div>
                    {touched.cvv && errors.cvv ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.cvv}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='d-flex mb-5 cursor-pointer'>
                  <div className='d-flex' onClick={() => setBillToggle(!billToggle)}>
                    <h3>Billing Information</h3>
                    <KTSVG
                      path={`/media/icons/duotone/Navigation/${
                        billToggle ? 'Angle-up' : 'Angle-down'
                      }.svg`}
                      className='svg-icon-1'
                    />
                  </div>
                </div>
                <div className={`${billToggle ? 'd-none' : 'd-block'} ps-5`}>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2 required'>Company Name</label>
                      <Field
                        as='input'
                        name='billingCompanyName'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                      />
                      {touched.billingCompanyName && errors.billingCompanyName ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.billingCompanyName}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2 required'>First Name</label>
                      <Field
                        as='input'
                        name='contactFirstName'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                      />
                      {touched.contactFirstName && errors.contactFirstName ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.contactFirstName}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2 required'>Last Name</label>
                      <Field
                        as='input'
                        name='contactLastName'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                      />
                      {touched.contactLastName && errors.contactLastName ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.contactLastName}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2 required'>Phone Number</label>
                      <Field
                        as='input'
                        name='billPhoneNumber'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                      />
                      {touched.billPhoneNumber && errors.billPhoneNumber ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.billPhoneNumber}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Fax Number</label>
                      <Field
                        as='input'
                        name='billFaxNumber'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                      />
                      {touched.billFaxNumber && errors.billFaxNumber ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.billFaxNumber}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2 required'>Address Line</label>
                      <Field
                        as='input'
                        name='billAddress'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                      />
                      {touched.billAddress && errors.billAddress ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.billAddress}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2 required'>City</label>
                      <Field
                        as='input'
                        name='billCity'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                      />
                      {touched.billCity && errors.billCity ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.billCity}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2 required'>State</label>
                      <CustomDropDown
                        data={STATES}
                        defaultValue={billState}
                        dirUp={true}
                        selectedValue={(code: string) => setFieldValue('billState', code)}
                      />
                      <Field
                        as='input'
                        name='billState'
                        className='form-control form-control-solid d-none'
                      />
                      {touched.billState && errors.billState ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.billState}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2 required'>Zip Code</label>
                      <Field
                        as='input'
                        name='billZip'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                      />
                      {touched.billZip && errors.billZip ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.billZip}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-stack'>
                  <div className='me-5'>
                    <label className='fs-6 fw-bold form-label'>
                      Save Card for further billing?
                    </label>
                    <div className='fs-7 fw-bold text-muted'>
                      If you need more info, please check budget planning
                    </div>
                  </div>
                  <div className='form-check form-switch form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      name='notifications'
                      defaultChecked={isSave}
                      onChange={() => setIsSave(!isSave)}
                      // disabled={isAdmin ? true : false}
                    />
                    <label className='form-check-label'>Save Card</label>
                  </div>
                </div>
                <div className='fv-plugins-message-container mt-5 invalid-feedback'>
                  <div>{invalidMessage}</div>
                </div>
              </div>
              <div className='text-center pt-15'>
                <button type='button' className='btn btn-light me-3' onClick={() => onHide(true)}>
                  Discard
                </button>
                <button type='submit' className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}>
                  Submit
                  {submit && (
                    <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
