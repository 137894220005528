import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router'
import toast from 'react-hot-toast'
import { ConfirmModal } from '../../components/_modal/ConfirmModal'
import { removeCustomer, getCustomers } from './redux/customerCRUD'
import { UserModel } from '../../../types'
import { RootState } from '../../../setup'
import { connect } from 'react-redux'

type Props = {
  customers: UserModel[],
  auth: any
}

const DeleteCustomer: React.FC<Props> = ({ customers, auth }) => {
  const { id }: any = useParams()
  const history = useHistory()
  const [showConfirmAlert, setConfirmAlert] = useState<boolean>(true)

  const customer = customers.find((customer: UserModel) => customer.id === parseInt(id))

  const onConfirm = () => {
    removeCustomer(id)
      ?.then(() => {
        toast.success('Successfully removed the customer')

        return getCustomers(auth.activeCustomerIds)
      })
      .catch(() => toast.error('Failed to remove the customer'))
      .finally(() => {
        setConfirmAlert(false)
        history.push('/dashboard')
      })
  }
  const onCancel = () => {
    setConfirmAlert(false)
    history.push('/dashboard')
  }
  const content = `Are you sure to delete customer ${customer?.firstname} ${customer?.lastname}?`
  return (
    <>
      {showConfirmAlert && (
        <ConfirmModal
          content={content}
          yesText='Confirm'
          noText='Cancel'
          selectYes={onConfirm}
          selectNo={onCancel}
        />
      )}
    </>
  )
}

const mapState = (state: RootState) => ({
  customers: state.customers?.customers,
  auth: state.auth,
})
const connector = connect(mapState, null)
export default connector(DeleteCustomer)
