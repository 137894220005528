import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { Accordion } from 'react-bootstrap-v5'
import { Customer, UserModel, BillingCompany } from '../../../../types'
import { getAllCustomerLocationUpdate, getAllCustomerIds } from '../TerritoryMapCrud';
import CustomToggle from '../../../components/CustomToggle';
import { KTSVG } from '../../../../_metronic/helpers';
import { SearchDropDown as CustomerSearchDropDown} from '../../../components/SearchDropDown';
import DatePicker from 'react-datepicker'

const updateAllCustomerLatitudeLongitude = async () => //DANGEROUS, should only be used once.
{
  //TODO: pull all customers and update their latitude, longitute and LatitudeLongitudeUpdated on the SQL.
  const googleResponse = await (getAllCustomerLocationUpdate());
}
interface mapFilterProps {
  handleCancelCustomer: () => void
  handleFilter: (filters: any, fromSearch: boolean) => Promise<void>; 
}

const MapFilter: React.FC<mapFilterProps> = ({ 
  handleCancelCustomer,
  handleFilter,

}) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [status, setStatus] = useState<string>('active');
  const [isReset, setIsReset] = useState<boolean>(false)
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [customers, setCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>()
  const [selectedCustomers, setSelectedCustomers] = useState<
    { id: string; name: string | undefined }[]
  >([])
  const handleStatusChange = (newStatus: string) => {
    setStatus(newStatus);
  };
  const handleCustomeCancel = (customerId: string) => {
    setSelectedCustomers(selectedCustomers.filter((customer) => customer.id !== customerId))
    handleCancelCustomer()
  }
  const handleCustomerSelect = (customer: { id: string; name: string | undefined }) => {
    const isExist = selectedCustomers.find((c) => c.id === customer.id)

    if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
    setIsReset(false)
  }
  const handleReject = () => {
    setIsReset(true)

    handleFilter({ status },false)
  }
  useEffect(() => {
    const fetchCustomerIds = async () => {
      const customerIds = await getAllCustomerIds();
      console.log(customerIds);
      const transformedCustomers = customerIds.data.map((customer: any) => ({
        id: customer.customerId,
        name: customer.companyName
      }));

      setCustomers(transformedCustomers);
      
    };
  
    fetchCustomerIds();
  }, []);
  return (
    <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
       
          {/* Toggle for expanding and collapsing the section */}
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
                <CustomToggle eventKey='filter-0'>Customer ID</CustomToggle>
              </div>
          <Accordion.Collapse eventKey='filter-0' className='ms-8'>
          <>
                  <div>
                    {selectedCustomers?.map((customer, index) => {
                      return (
                        <div
                          key={index}
                          className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                        >
                          <span>
                            {customer.id} - {customer?.name}
                          </span>
                          <span
                            className='text-hover-primary cursor-pointer'
                            onClick={() => handleCustomeCancel(customer.id)}
                          >
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Close.svg'
                              className='svg-icon-1'
                            />
                          </span>
                        </div>
                      )
                    })}
                  </div>
                  <CustomerSearchDropDown
                    data={customers}
                    edit={isReset ? 'reset' : ''}
                    isNBS={isNBS}
                    selectedValue={(customer) => handleCustomerSelect(customer)}
                  />
                </>
                
          </Accordion.Collapse>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
                <CustomToggle eventKey='filter-1'>Active Date</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-1'>
            <>
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={startDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => {
                    setStartDate(date)
                  }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={endDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>
          <div className='separator border-gray-200'></div>
          <div className='d-flex justify-content-end'>
          <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
              <button 
               className='btn btn-sm btn-primary'
              onClick={updateAllCustomerLatitudeLongitude}>Load Customer Lat Lon</button>
              <button
               className='btn btn-sm btn-primary'
               style={{ marginLeft: '10px' }} 
              onClick={() => handleFilter({ startDate, endDate,selectedCustomers },false)}>Apply</button>
            </div>
        </Accordion>
      </div>
    </div>
  );
};

export default MapFilter;