/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { FC, useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Card } from 'react-bootstrap-v5'
import toast from 'react-hot-toast'
import moment from 'moment'
import { PageTitle } from '../../../_metronic/layout/core'
import { AttachmentModal } from '../service-quotes/components/AttachmentModal'
import { getQuoteAttachment } from '../service-quotes/serviceQuotesCrud'
import { getErrorMessage, customQuotesSort } from '../../../helper'
import { updateQuotePaymentOption, chargeQuotePayment } from '../../services/payment'
import { KTSVG } from '../../../_metronic/helpers'
import { TableColumn } from 'react-data-table-component'
import { QuoteModel, PaymentOption, fileType, Customer } from '../../../types'
import { RootState } from '../../../setup'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { ActionDropDown } from '../../modules/service/_dropdown/ActionDropDown'
import { ConfirmModal } from '../../components/_modal/ConfirmModal'
import UpdatePaymentOption from '../../components/_modal/UpdatePaymentOption'
import ViewPaymentOption from '../../components/_modal/ViewPaymentOption'
import CompletePay from '../../components/_modal/CompletePay'
import DataTableBase from '../../components/DataTableBase'
import FilterDropDown from '../../modules/sales/components/FilterDropDown'
import * as serviceOrders from './serviceOrdersRedux'
import * as levelHelper from '../../../helper/level.helper'

interface ReduxStateValueProps {
  customerIds: any
  serviceOrders: QuoteModel[]
  loading: boolean
  totals: Number
  quoteNumberSlug: any
  auth: IAuthState & PersistPartial
}

const mapState = (state: RootState) => ({
  customerIds: state.auth?.activeCustomerIds,
  serviceOrders: state.serviceOrders.serviceOrders,
  loading: state.serviceOrders.loading,
  totals: state.serviceOrders.totals,
  quoteNumberSlug: state.serviceOrders.quoteNumberSlug,
  auth: state.auth,
})

const connector = connect(mapState, { ...serviceOrders.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type ServiceOrdersProps = ReduxStateValueProps & PropsFromRedux

const ServiceOrdersPage: FC<ServiceOrdersProps> = (props) => {
  const { getServiceOrders, customerIds, loading, auth } = props

  const initialFilter = {
    status: ['active', 'IC', 'inactive', 'invoiced', 'pupi', 'qcc', 'rec', 'ship', 'stbb', 'pd'],
    from: moment().subtract(1, 'years').utc().format('Y-MM-DD HH:mm'),
    to: moment().utc().format('Y-MM-DD HH:mm'),
    isReset: false,
  }

  const [isNBS, setIsNBS] = useState<boolean>(levelHelper.isNBS(auth.user?.type))
  const [orders, setOrders] = useState<QuoteModel[]>([])
  const [slug, setSlug] = useState<string>('')
  const [quoteNumberSlug, setQuoteNumberSlug] = useState<any>(props.quoteNumberSlug)
  const [attachOrderId, setAttachOrderId] = useState<number>(0)
  const [updateOrderId, setUpdateOrderId] = useState<number>(0)
  const [viewOrderId, setViewOrderId] = useState<number>(0)
  const [completeOrderId, setCompleteOrderId] = useState<number>(0)
  const [quoteNumber, setSetQuoteNumber] = useState<string>('')
  const [updateCustomerId, setUpdateCustomerId] = useState<string>('')
  const [completeCustomerId, setCompleteCustomerId] = useState<string>('')
  const [totalAmount, setTotalAmount] = useState<string | undefined>('')
  const [nbsCustomerId, setNbsCustomerId] = useState<string[]>(['nbs'])
  const [attachment, setAttachment] = useState<fileType[]>([])
  const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
  const [filter, setFilter] = useState(initialFilter)
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false)
  const [showViewModal, setShowViewModal] = useState<boolean>(false)
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<boolean>(false)
  const [quoteAction, setQuoteAction] = useState<PaymentOption[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDir, setSortDir] = useState(null)

  const [confirmAlert, setConfirmAlert] = useState({
    show: false,
    content: '',
    onConfirm: () => { },
  })

  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>

  const handleAttachmentModal = useCallback((dbKey: number) => {
    setShowAttachmentModal(true)
    setAttachOrderId(dbKey)

    getQuoteAttachment(dbKey)
      .then((res) => {
        let attachments: fileType[] = []

        res.data.forEach((name: string) => {
          attachments.push({
            name: name, size: 0, type: 'old', checked: false,
            id: '',
            quoteId: 0
          })
        })
        setAttachment(attachments)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }, [])

  const showConfirmAlert = useCallback(
    (content: string, onConfirm: any) => {
      setConfirmAlert({ show: true, content, onConfirm })
    },
    [confirmAlert]
  )

  const columns: TableColumn<QuoteModel>[] = useMemo(
    () => [
      {
        name: '',
        cell: (row) => (
          <span
            className='btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable'
            onClick={() => handleAttachmentModal(row.dbkey)}
          >
            <KTSVG path='/media/icons/duotone/Communication/paperslip.svg' className='svg-icon-2' />
            {row.attachment && row.attachment.length !== 0 && (
              <span className='attachment-count'>{row.attachment && row.attachment.length}</span>
            )}
          </span>
        ),
        width: '40px',
        style: {
          minWidth: '40px !important',
          maxWidth: '40px !important',
        },
      },
      {
        name: 'Actions',
        cell: (row) => (
          <ActionDropDown
            data={row}
            auth={auth}
            handleUpdatePayment={(row: QuoteModel) => handleUpdatePayment(row)}
            handleViewPayment={(row: QuoteModel) => handleViewPayment(row)}
            handleCompletePay={handleCompletePay}
          />
        ),
        width: 'calc(13% - 40px)',
        style: {
          minWidth: 'calc(13% - 40px) !important',
          maxWidth: 'calc(13% - 40px) !important',
        },
      },
      {
        name: '#',
        selector: (row) => row.dbkey,
        sortable: true,
        width: '8%',
        style: {
          minWidth: '8% !important',
          maxWidth: '8% !important',
        },
      },
      {
        name: 'CUSTOMER',
        sortField: 'id',
        selector: (row) => row.customerId,
        cell: (row) => (
          <span>
            <span>{row.customerId}</span>
            <br />
            <span>{row.customerName}</span>
          </span>
        ),
        sortable: true,
        width: '12%',
        style: {
          minWidth: '12% !important',
          maxWidth: '12% !important',
        },
      },
      {
        name: 'QUOTE NUMBER',
        sortField: 'quote_number',
        selector: (row) => row.quoteNumber,
        sortable: true,
        width: '15%',
        style: {
          minWidth: '15% !important',
          maxWidth: '15% !important',
        },
      },
      {
        name: 'Transaction Type',
        sortField: 'transactionType',
        selector: (row) => row.transactionType,
        sortable: true,
        width: '12%',
        style: {
          minWidth: '12% !important',
          maxWidth: '12% !important',
        },
      },
      {
        name: 'CREATED ON',
        sortField: 'created',
        selector: ({ createdAt }) => createdAt?.valueOf(),
        cell: ({ createdAt }) => moment(createdAt).format('MM/DD/YY'),
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        name: 'Attention',
        sortField: 'attention',
        selector: (row) => row.attention ?? '',
        sortable: true,
        width: '15%',
        style: {
          minWidth: '15% !important',
          maxWidth: '15% !important',
        },
      },
      {
        name: 'Status',
        sortField: 'status',
        selector: (row) => row.status ?? '',
        sortable: true,
        width: '15%',
        style: {
          minWidth: '15% !important',
          marginRight: '25px',
        },
      },
    ],
    [handleAttachmentModal]
  )

  const handleUpdatePayment = (data: QuoteModel) => {
    setUpdateOrderId(data.dbkey)
    setUpdateCustomerId(data?.customerId)

    setQuoteAction(data.paymentOptions)
    setShowOptionsModal(true)
  }

  const handleViewPayment = (data: QuoteModel) => {
    setViewOrderId(data.dbkey)

    setQuoteAction(data.paymentOptions)
    setShowViewModal(true)
  }

  const handleCompletePay = (data: QuoteModel) => {
    setCompleteOrderId(data.dbkey)
    setSetQuoteNumber(data.quoteNumber)
    setTotalAmount(data?.total)
    setCompleteCustomerId(data?.customerId)
    setQuoteAction(data.paymentOptions)
    setShowCompleteModal(true)
  }

  const handleCharge = (payload: any) => {
    setUpdateStatus(false)

    chargeQuotePayment(payload)
      .then((res) => {
        getOrders()
        setUpdateStatus(true)
        if (res.data === 'success') toast.success('Charged Customer Profile successfully.')
        else toast.error(res.data)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setQuoteAction([])
        setShowCompleteModal(false)
      })
  }

  const handleHide = () => {
    setShowOptionsModal(false)
    setShowViewModal(false)
    setShowCompleteModal(false)
  }

  const handleCreate = (values: any) => {
    setUpdateStatus(false)

    updateQuotePaymentOption(updateOrderId, values)
      .then((res) => {
        getOrders()
        toast.success(res.data)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setUpdateStatus(true)
        setQuoteAction([])
        setShowOptionsModal(false)
      })
  }

  const getOrders = () => {
    getServiceOrders({
      customerIds: isNBS ? nbsCustomerId : customerIds,
      status: filter.status,
      from: filter.from,
      to: filter.to,
      page,
      perPage,
      slug,
      quoteNumberSlug,
      quotePrefix: auth.user?.quotePrefix,
      sortColumn,
      sortDir,
    })
  }

  const handleFilter = (filters: any) => {
    setFilter(filters)
    const { selectedCustomers, isReset } = filters

    if (isReset === true) {
      setFilter(initialFilter)
      setPage(1)
    } else {
      setFilter(filters)
      setPage(1)

      let filterIds: string[] = []

      selectedCustomers.forEach((customer: Customer) => {
        filterIds.push(customer.id)
      })

      filterIds = isNBS && filterIds.length === 0 ? ['nbs'] : filterIds

      setNbsCustomerId(filterIds)
    }
  }

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  useEffect(() => {
    setOrders(props.serviceOrders)
    setTotalRows(props.totals)
  }, [props.totals, props.serviceOrders])

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(auth.user?.type))
    setPage(1)
  }, [auth])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPage(page)
    setPerPage(newPerPage)
  }

  const handleSort = (column: any, sortDirection: any) => {
    setSortColumn(column.sortField)
    setSortDir(sortDirection)
  }

  useEffect(() => {
    setPage(1)
  }, [slug])

  useEffect(() => {
    getOrders()
  }, [page, perPage, slug, quoteNumberSlug, sortColumn, sortDir, filter, props.customerIds])

  useEffect(() => {
    if (quoteNumberSlug === auth.user?.quotePrefix) {
      props.setQuoteNumberSlug(quoteNumberSlug)
    } else {
      props.setQuoteNumberSlug('')
    }
  }, [quoteNumberSlug])

  useEffect(() => {
    if (isNBS) {
      if (quoteNumberSlug === null) setQuoteNumberSlug(auth.user?.quotePrefix)
    } else {
      setQuoteNumberSlug('')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Service Orders</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1 me-3'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Service Orders'
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
            </div>
            {isNBS && (
              <div className='d-flex align-items-center position-relative my-1'>
                <KTSVG
                  path='/media/icons/duotone/General/Search.svg'
                  className='svg-icon-1 position-absolute ms-6'
                />
                <input
                  type='text'
                  data-kt-customer-table-filter='search'
                  className='form-control form-control-solid w-250px ps-15 border-0'
                  placeholder='Search Quote Number'
                  value={quoteNumberSlug || ''}
                  onChange={(e: any) => setQuoteNumberSlug(e.target.value)}
                />
              </div>
            )}
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              <div className='filter-dropdown ms-3'>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown
                  handleFilter={handleFilter}
                  handleCancelCustomer={handleCancelCustomer}
                  initialFilterStatus={initialFilter.status}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 service-quotes-body'>
          <DataTableBase
            selectableRows={false}
            columns={columns}
            data={orders}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            sortFunction={customQuotesSort}
            onSort={handleSort}
            sortServer
            persistTableHead
          />
        </Card.Body>
      </Card>
      <AttachmentModal
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        data={attachment}
        quoteId={attachOrderId}
        getQuotes={getOrders}
      />
      <UpdatePaymentOption
        submit={handleCreate}
        status={updateStatus}
        show={showOptionsModal}
        onHide={handleHide}
        actions={quoteAction}
        orderId={updateOrderId}
        customerId={updateCustomerId}
        showConfirmAlert={showConfirmAlert}
      />
      <ViewPaymentOption
        show={showViewModal}
        onHide={handleHide}
        actions={quoteAction}
        orderId={viewOrderId}
      />
      <CompletePay
        submit={handleCharge}
        status={updateStatus}
        show={showCompleteModal}
        onHide={handleHide}
        actions={quoteAction}
        orderId={completeOrderId}
        quoteNumber={quoteNumber}
        totalAmount={totalAmount}
        customerId={completeCustomerId}
        showConfirmAlert={showConfirmAlert} po2={undefined}      />
      {confirmAlert.show && (
        <ConfirmModal
          content={confirmAlert.content}
          yesText='Confirm'
          noText='Cancel'
          selectYes={() => {
            confirmAlert.onConfirm()
            setConfirmAlert({ ...confirmAlert, show: false })
          }}
          selectNo={() => setConfirmAlert({ ...confirmAlert, show: false })}
        />
      )}
    </>
  )
}

export default connector(ServiceOrdersPage)
