import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { Accordion } from 'react-bootstrap-v5'
import { SearchDropDown } from '../../../components/SearchDropDown'
import { getAllCustomerIds } from '../../../pages/asset-mangement/AssetManagementCrud'
import { UserModel, Customer, statusType } from '../../../../types'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import CustomToggle from '../../../components/CustomToggle'
import FilterList from '../../../components/FilterList'
import * as levelHelper from '../../../../helper/level.helper'
import { getErrorMessage } from '../../../../helper/response.helper'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { KTSVG } from '../../../../_metronic/helpers'
import { getQuoteFilterStatus } from '../../../pages/sales-quotes/salesQuotesCrud'

type FilterDropDownProps = {
  handleFilter: (filter: any) => void
  handleCancelCustomer: () => void
}

const FilterDropDown: React.FC<FilterDropDownProps> = ({ handleFilter, handleCancelCustomer }) => {
  const initialEndDate = new Date()
  const initialStartDate = new Date()
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1)

  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<any>(initialStartDate)
  const [endDate, setEndDate] = useState<any>(initialEndDate)
  const [statusFilters, setStatusFilters] = useState<Array<string>>(['Active'])
  const [selectAllStatus, setSelectAllStatus] = useState<boolean>(false)
  const [confirmFilters, setConfirmFilters] = useState<Array<string>>(['Yes', 'No'])
  const [transFilters, setTransFilters] = useState<Array<string>>(['Cal_Lab', 'Service', 'Repair'])
  const [customers, setCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([])
  const transList = ['Cal_Lab', 'Service', 'Repair']
  const confirmList = ['Yes', 'No']
  const [statusList, setStatusList] = useState<statusType[]>([])

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const handleChange = (statusDescription: string, isChecked: boolean) => {
    const status = statusList.find((s: statusType) => s.description === statusDescription)

    if (!status) {
      return
    }

    if (isChecked === true) setStatusFilters([...statusFilters, status?.dbkey])
    else setStatusFilters(statusFilters.filter((s) => s !== status?.dbkey))
  }

  const handleSelect = (customer: { id: string; name: string | undefined }) => {
    const isExist = selectedCustomers.find((c) => c.id === customer.id)

    if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
    setIsReset(false)
  }

  const handleCancel = (customerId: string) => {
    setSelectedCustomers(selectedCustomers.filter((customer) => customer.id !== customerId))
    handleCancelCustomer()
  }

  const handleChangeTrans = (status: string, isChecked: boolean) => {
    if (isChecked === true) setTransFilters([...transFilters, status])
    else setTransFilters(transFilters.filter((s) => s !== status))
  }

  const handleChangeConfirm = (status: string, isChecked: boolean) => {
    if (isChecked === true) setConfirmFilters([...confirmFilters, status])
    else setConfirmFilters(confirmFilters.filter((s) => s !== status))
  }

  const handleReject = () => {
    setStatusFilters(['Active'])
    setTransFilters(['Cal_Lab', 'Service', 'Repair'])
    setConfirmFilters(['Yes', 'No'])
    setStartDate(initialStartDate)
    setEndDate(initialEndDate)
    setIsReset(true)
    handleFilter({
      isReset: true,
    })
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(user?.type))

    getQuoteFilterStatus()
      .then((res) => {
        setStatusList(res.data as statusType[])
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })

    if (levelHelper.isNBS(user?.type)) {
      setSelectedCustomers([])
      getAllCustomerIds().then((res) => {
        setCustomers(res.data)
      })
    } else {
      let uCustomers: Array<Customer> = []

      if (user.customerIds) {
        user.customerIds.forEach((c) => {
          uCustomers.push({ id: c.customerId, name: c.companyName })
        })
      }
      setCustomers(uCustomers)
    }
  }, [user])

  // Handler when change select all for status
  const handleSelectAllStatus = () => {
    if (!selectAllStatus) {
      const statusDBKeys = statusList.map((s: statusType) => s.dbkey)
      setStatusFilters(statusDBKeys)
    } else {
      setStatusFilters([])
    }
  }

  useEffect(() => {
    const statusDBKeys = statusList.map((s: statusType) => s.dbkey)
    if (statusDBKeys.every((status) => statusFilters.includes(status))) {
      setSelectAllStatus(true)
    } else {
      setSelectAllStatus(false)
    }
  }, [statusFilters, statusList])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          <>
            <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
              <CustomToggle eventKey='filter-0'>Customer ID</CustomToggle>
            </div>
            <Accordion.Collapse eventKey='filter-0' className='ms-8'>
              <>
                <div>
                  {selectedCustomers?.map((customer, index) => {
                    return (
                      <div
                        key={index}
                        className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                      >
                        <span>
                          {customer.id} - {customer?.name}
                        </span>
                        <span
                          className='text-hover-primary cursor-pointer'
                          onClick={() => handleCancel(customer.id)}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Navigation/Close.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </div>
                    )
                  })}
                </div>
                <SearchDropDown
                  data={customers}
                  edit={isReset ? 'reset' : ''}
                  isNBS={isNBS}
                  selectedValue={(customer) => handleSelect(customer)}
                />
              </>
            </Accordion.Collapse>
          </>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-1'>Status</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-1' className='ms-8'>
            <ul className='list-unstyled'>
              <FilterList
                key={`status-all`}
                status={'Select All'}
                isChecked={selectAllStatus}
                handleChange={handleSelectAllStatus}
              />
              <li className='separator border-gray-200 mb-1'></li>
              {statusList.map(({ dbkey, description }, index) => {
                return (
                  <FilterList
                    key={`status-${index}`}
                    status={description}
                    isChecked={!!statusFilters.includes(dbkey)}
                    handleChange={handleChange}
                  />
                )
              })}
            </ul>
          </Accordion.Collapse>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-4'>Confirmed</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-4' className='ms-8'>
            <ul className='list-unstyled'>
              {confirmList.map((conf, index) => {
                return (
                  <FilterList
                    key={`con-${index}`}
                    status={conf}
                    isChecked={true}
                    handleChange={handleChangeConfirm}
                  />
                )
              })}
            </ul>
          </Accordion.Collapse>
          <div className='separator border-gray-200'></div>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-3'>Transaction Type</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-3' className='ms-8'>
            <ul className='list-unstyled'>
              {transList.map((trans, index) => {
                return (
                  <FilterList
                    key={`trans-${index}`}
                    status={trans}
                    isChecked={true}
                    handleChange={handleChangeTrans}
                  />
                )
              })}
            </ul>
          </Accordion.Collapse>
          <div className='separator border-gray-200'></div>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-2'>Created on</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-2'>
            <>
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={startDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => {
                    setStartDate(date)
                  }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={endDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setIsReset(false)
                handleFilter({
                  selectedCustomers: selectedCustomers,
                  status: statusFilters,
                  confirm: confirmFilters,
                  from: moment(startDate).utc().format('Y-MM-DD HH:mm'),
                  to:
                    endDate !== null
                      ? moment(endDate).utc().format('Y-MM-DD HH:mm')
                      : moment().utc().format('Y-MM-DD HH:mm'),
                  transType: transFilters,
                })
              }}
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
