/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Divider, Typography } from '@material-ui/core'
import { CUSTOMER_STATUS, CUSTOMER_LEVELS } from '../../../../../data/constants'
import { CustomersProps, UserModel } from '../../../../../types'
import { KTSVG } from '../../../../../_metronic/helpers'
import { SendNotification } from '../../../../components/_modal/SendNotification'
import { getCompanyName, getTimeInCentralTime, checkIfImageExists } from '../../../../../helper'
import Table from './Table'
import MultiSelect from '../../../nbs-admin/component/NBSAdminTable/MultiSelect'
import { useSelector, shallowEqual, ConnectedProps, connect } from 'react-redux'
import * as levelHelper from '../../../../../helper'
import * as customersRedux from '../../../customer/redux/customerRedux'
import { RootState } from '../../../../../setup'
import FilterDropDown from '../../../../modules/customers/components/FilterDropDown'

const mapState = (state: RootState) => ({
  customers: state.customers.customers,
  loading: state.customers.loading,
  auth: state.auth,
})

const connector = connect(mapState, {
  ...customersRedux.actions,
})

type PropsFromRedux = ConnectedProps<typeof connector>
type CustomerDataProps = CustomersProps & PropsFromRedux

const CustomersTable: React.FC<CustomerDataProps> = (props) => {
  const initialFilter = React.useMemo(() => {
    return {
      level: Object.values(CUSTOMER_LEVELS),
      status: ['active', 'invited'],
      from: '',
      to: moment().utc().format('Y-MM-DD HH:mm'),
      isReset: false,
    }
  }, [])

  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>

  const [data, setData] = useState<UserModel[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [receiver, setReceiver] = useState<UserModel>()
  const [, setFilter] = useState(initialFilter)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }: any) => {
    const options = React.useMemo(() => {
      const options = new Set<any>()
      preFilteredRows.forEach((row: any) => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <MultiSelect
        options={options}
        value={filterValue || []}
        onChange={(selected) => {
          setFilter(selected)
        }}
      />
    )
  }

  const renderActions = useCallback(
    (value: any) => (
      <>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <span
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            title='Send Notification'
            onClick={() => handleSelect(value.row.original)}
          >
            <KTSVG
              path='/media/icons/duotone/Communication/Group-chat.svg'
              className='svg-icon-3'
            />
          </span>
          <Link
            to={`/customers/invite/${value.row.original.id}`}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            title='Resend-Invite'
          >
            <KTSVG path='/media/icons/duotone/General/Settings-1.svg' className='svg-icon-3' />
          </Link>
          {!levelHelper.isCustomerTechnician(user?.level) &&
            <>
              <Link
                to={`/customers/edit/${value.row.original.id}`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                title='Edit'
              >
                <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
              </Link>
              <Link
                to={`/customers/delete/${value.row.original.id}`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                title='Delete'
              >
                <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
              </Link>
            </>
          }
        </div>
      </>
    ),
    [user]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Customers',
        id: 'basic-info',
        accessor: (originalRow: any) => ({
          name: `${originalRow.firstname} ${originalRow.lastname}`,
          avatar: originalRow.avatar,
          id: originalRow.id,
        }),
        Cell: ({ cell: { value } }: any) => reanderBasicInfo(value),
        filter: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ cell: { value } }: any) => renderSimpleString(value),
        filter: 'includes',
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: ({ cell: { value } }: any) => renderSimpleString(value),
        filter: 'selectIncludes',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Company',
        id: 'company-info',
        accessor: (originalRow: any) => ({
          customerIds: originalRow.customerIds,
        }),
        Cell: ({ cell: { value } }: any) => renderCompanyInfo(value),
        filter: 'company',
      },
      {
        Header: 'Active on',
        accessor: 'lastLogin',
        Cell: ({ cell: { value } }: any) => renderDate(value),
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { value } }: any) => renderSimpleString(value),
        filter: 'selectIncludes',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Actions',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ cell }: any) => renderActions(cell),
        className: 'text-end',
      },
    ],
    [renderActions]
  )

  const activeUsersCnt = useMemo(
    () => props.data.filter((c: any) => c.status === CUSTOMER_STATUS.ACTIVE).length,
    [props.data]
  )
  const invitedUsersCnt = useMemo(
    () => props.data.filter((c: any) => c.status === CUSTOMER_STATUS.INVITED).length,
    [props.data]
  )

  const filterByCompanyName = (customer: UserModel, slug: string) => {
    if (customer.customerIds?.length === 0) {
      return false
    } else {
      let company = ''

      customer.customerIds?.forEach((c) => {
        company = `${c.companyName}(${c.customerId})`
      })

      return company.includes(slug.toUpperCase())
    }
  }

  const handleHide = () => {
    setShow(false)
  }

  const handleSelect = (user: UserModel) => {
    setShow(true)

    setReceiver(user)
  }

  interface CustomerId {
    customerId: string;
    companyName: string;
  }
  
  interface UserModel {
    firstname: string;
    lastname: string;
    email?: string;
    level?: string;
    status?: string;
    customerIds: CustomerId[];
  }
  
  const hanldeSearch = (value: string) => {
    setData(
      props.customers.filter((customer: UserModel) => {
        const searchQuery = value.toUpperCase();
        // Check if customer's first name, last name, email, level, or status matches the search query
        const matchesDetails = `${customer.firstname} ${customer.lastname}`
          .toUpperCase()
          .includes(searchQuery) ||
          customer.email?.toUpperCase().includes(searchQuery) ||
          customer.level?.toUpperCase().includes(searchQuery) ||
          customer.status?.toUpperCase().includes(searchQuery);
  
        // Check if any customerId or companyName in customerIds matches the search query
        const matchesCustomerIds = customer.customerIds.some(id => 
          id.customerId.toUpperCase().includes(searchQuery) ||
          id.companyName.toUpperCase().includes(searchQuery)
        );
  
        // Return true if any conditions are met
        return matchesDetails || matchesCustomerIds || filterByCompanyName(customer, value);
      })
    );
  }

  useEffect(() => {
    setData(props.customers)
  }, [props.customers])

  const reanderBasicInfo = (value: any) => (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        <img
          src={checkIfImageExists(value.avatar)}
          alt={value.name}
          className='mw-45px mh-45px mw-md-45px mh-md-45px w-auto h-auto'
        />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <Link
          to={`/customers/view/${value.id}`}
          title='View'
          className='text-dark fw-bolder text-hover-primary fs-6'
        >
          {`${value.name}`}
        </Link>
      </div>
    </div>
  )

  const renderDate = (value: any) => {
    let time = getTimeInCentralTime(value)

    return (
      <div className='d-flex align-items-center justify-content-start'>
        <p className='text-dark fw-bolder fs-6 capitalize mb-0'>{time}</p>
      </div>
    )
  }

  const renderSimpleString = (value: any) => (
    <div className='d-flex align-items-center justify-content-start'>
      <p className='text-dark fw-bolder fs-6 capitalize mb-0'>{value}</p>
    </div>
  )

  const renderCompanyInfo = (value: any) => (
    <>
      <p className='text-dark fw-bolder text-hover-primary d-block mb-0 fs-6'>
        {value.customerIds ? getCompanyName(value.customerIds) : ''}
      </p>
    </>
  )

  const handleFilter = useCallback(
    (filters: any) => {
      const { isReset } = filters

      if (isReset === true) {
        setFilter(initialFilter)
        props.getCustomers([], [], initialFilter)
      } else {
        setFilter(filters)

        props.getCustomers([], [], filters)
      }
    },
    [initialFilter, props]
  )

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  return (
    <>
      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Customer Administration</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Invited: {invitedUsersCnt}</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Active: {activeUsersCnt}</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            {!levelHelper.isCustomerTechnician(user?.level) && (
              <Link to='/customers/new' className='btn btn-sm btn-light-primary'>
                <KTSVG
                  path='media/icons/duotone/Communication/Add-user.svg'
                  className='svg-icon-3'
                />
                New Customer
              </Link>
            )}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <Divider style={{ backgroundColor: 'rgba(0,0,0,.5)', marginBottom: 16 }} />
          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Customers'
                onChange={(e) => hanldeSearch(e.target.value)}
              />
            </div>
            <div className='card-toolbar'>
              <div className='d-flex' data-kt-customer-table-toolbar='base'>
                <div className='filter-dropdown ms-3'>
                  <button
                    type='button'
                    className='btn btn-light-primary me-3'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Filter options'
                    ref={filterRef}
                  >
                    <KTSVG
                      path='/media/icons/duotone/Text/Filter.svg'
                      className='svg-icon-2 text-light'
                    />
                    Filter
                  </button>
                  <FilterDropDown
                    handleFilter={handleFilter}
                    handleCancelCustomer={handleCancelCustomer}
                    isNBS={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive' style={{ minHeight: 200, maxHeight: 600 }}>
            {data.length > 0 && <Table key='customer_table' columns={columns} data={data} />}
            {!data.length && (
              <Typography>There are no customers yet. Please invite one.</Typography>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <SendNotification show={show} onHide={handleHide} receiver={receiver} type='customer' />
    </>
  )
}

export default connector(CustomersTable)
