/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import toast from 'react-hot-toast'
import { Card } from 'react-bootstrap-v5'
import { TableColumn } from 'react-data-table-component'
import { RootState } from '../../../setup'
import { Asset, SaveModal, Customer, EquipmentType } from '../../../types'
import { PageTitle } from '../../../_metronic/layout/core'
import { deleteAsset, getAssets as getAssets2 } from './AssetManagementCrud'
import { AssetActionModal } from './component/AssetActionModal'
import { ActionDropDown } from './component/ActionDropDown'
import { ConfirmModal } from '../../components/_modal/ConfirmModal'
import { KTSVG } from '../../../_metronic/helpers'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { ImportAssetsModal } from './component/ImportAssetsModal'
import { SaveImportedAssetsModal } from './component/SaveImportedAssetsModal'
import { ExportAssetsModal } from './component/ExportAssetsModal'
import { customAssetsSort } from '../../../helper/table.helper'
import FilterDropDown from './component/FilterByCustomer'
import * as assetManagementRedux from './AssetManagementRedux'
import * as levelHelper from '../../../helper/level.helper'
import DataTableBase from '../../components/DataTableBase'
import { getEquipmentTypes } from '../../services/service'

interface ReduxStateValueProps {
  assetManagement: Asset[]
  loading: boolean
  userId: number | undefined
  customerIds: string[] | undefined
  auth: IAuthState & PersistPartial
}
interface StateMaster {
  customerPayload: string[] | undefined;
  isNBS: boolean;
  customerProp: Customer[];
  perPage: number;
  slug: string;
  currentCustomerFilters: string[];
  currentPage: number;
}
type PropsFromRedux = ConnectedProps<typeof connector>
type AssetManagementProps = ReduxStateValueProps & PropsFromRedux

const AssetManagementPage: React.FC<AssetManagementProps> = (props) => {
  const { getAssets, assetManagement, loading, userId, customerIds, auth, pagination} = props  
  const [perPage, setPerPage] = useState<number>(10)
  const [totalNumber, setTotalNumber] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentCustomerFilters, setCurrentCustomerFilters] = useState<string[]>([])
  const [slugSearchTerm, setSlugSearchTerm] = useState<string>('')

  const [slug, setSlug] = useState<string>('')
  const [isNBS, setIsNBS] = useState<boolean>(levelHelper.isNBS(props.auth.user?.type))
  const [newAssets, setNewAssets] = useState<Asset[]>([])
  const [assets, setAssets] = useState<Asset[]>([])
  const [showImport, setShowImport] = useState<boolean>(false)
  const [allUpdatesDone, setAllUpdatesDone] = useState(false);
  const [showExport, setShowExport] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [customerPayload, setCustomerPayload] = useState<string[] | undefined>([])
  const [refreshImport, setRefreshImport] = useState<boolean>(false)
  const [customerProp, setCustomerProp] = useState<Customer[]>([{ id: '', name: '' }])
  const [stateMaster, setStateMaster] = useState<StateMaster>({
    customerPayload: ["FALSE"], // Initially an empty array or undefined
    isNBS: false,         // Default to false
    customerProp: [{ id: 'FALSE', name: 'FALSE' }], // Default to an array with one empty customer
    perPage: 10,          // Default per page items
    slug: '',             // Default slug empty
    currentCustomerFilters: [], // Default to an empty array
    currentPage: 1        // Start with the first page
  });
  const [saveData, setSaveData] = useState<SaveModal>({
    show: false,
    customerIds: [],
    mode: 'Replace',
    fileName: '',
    assets: [],
  })
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>

  const [equipmentTypes, setEquipmentTypes] = useState<EquipmentType[]>([])

  const initialAsset = useMemo(() => {
    return {
      dbkey: 0,
      CustomerID: '',
      seq: '',
      make: '',
      model: '',
      serial: '',
      unitid: '',
      dept: '',
      equipment_type: '',
      equipment_types: [],
    }
  }, [])
  const [updateAsset, setUpdateData] = useState<Asset>(initialAsset)

  const [modalData, setModalData] = useState<{ show: boolean; actionType: string }>({
    show: false,
    actionType: '',
  })

  const columns: TableColumn<Asset>[] = [
  
    {
      name: 'Customer ID',
      selector: (row) => row.CustomerID,
      sortable: true,
      width: '100px',
      style: {
        maxWidth: '100px !important',
      },
    },
    {
      name: 'SEQ',
      selector: (row) => row.seq,
      sortable: true,
      width: '100px',
      style: {
        maxWidth: '100px !important',
      },
    },
    {
      name: 'MANUFACTURER',
      selector: (row) => row.make,
      sortable: true,
      style: {
        maxWidth: 'unset !important',
      },
    },
    {
      name: 'MODEL',
      selector: (row) => row.model,
      sortable: true,
      style: {
        maxWidth: 'unset !important',
      },
    },
    {
      name: 'SERIAL',
      selector: (row) => row.serial,
      sortable: true,
      style: {
        maxWidth: 'unset !important',
      },
    },
    {
      name: 'UNIT ID',
      selector: (row) => row.unitid,
      sortable: true,
      style: {
        maxWidth: 'unset !important',
      },
    },
    {
      name: 'DEPARTMENT',
      selector: (row) => row.dept,
      sortable: true,
      style: {
        maxWidth: 'unset !important',
      },
    },
    {
      name: 'equipment type',
      selector: (row) => row.equipment_type,
      sortable: true,
      // width: '100px',
      style: {
        maxWidth: 'unset !important',
      },
    },
  ]
// Conditionally add the 'Action' column
if (isNBS) {
  columns.unshift({ // Use unshift to add the column at the beginning of the array
    name: 'Action',
    maxWidth: 'unset !important',
    cell: (row) => <ActionDropDown data={row} handleEdit={handleEdit} handleDelete={handleDelete} />,
    width: '100px',
  });
}
  const Assets = useMemo(() => {
    return assetManagement
  }, [assetManagement])

  const handleSearch = (e: any) => {
    setSlug(slugSearchTerm)
  }

  const handleCloseModal = useCallback((isShowConfirm: boolean) => {
    if (isShowConfirm === true) setShowConfirm(true)
    else {
      setModalData({ show: false, actionType: '' })
      setShowExport(false)
      setShowImport(false)
      setSaveData({ show: false, customerIds: [], mode: 'Replace', fileName: '', assets: [] })
    }

    setRefreshImport(false)
  }, [])

  const handleEdit = useCallback((data: Asset) => {
    setModalData({ show: true, actionType: 'Edit' })
    setUpdateData(data)
  }, [])

  const handleDelete = (data: Asset) => {
    deleteAsset(data.dbkey).then((res) => {
      toast.success(res.data)
      //getAssets({ customerIds: customerPayload })
      fetchData()
    })
  }

  const handleFilter = (filters: any) => {
    const { customers, isReset } = filters

    if (isReset === true)
      {
        if(currentPage == 1){fetchData()}
        else{setCurrentPage(1)}
      }
    else {
      let filterCustomerIds: string[] = []

      customers.forEach((customer: Customer) => {
        filterCustomerIds.push(customer.id)
      })

      if (filterCustomerIds.length === 0 && levelHelper.isNBS(auth.user?.type)) {filterCustomerIds.push('nbs')} //Check tomorrow.
      
      setCurrentPage(1); 
      setCurrentCustomerFilters(filterCustomerIds) //This will cause the use effect to trigger, grabbing the first page of the now filtered results
    }
  }

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const handleConfirmCancel = () => {
    setUpdateData(initialAsset)

    setShowConfirm(false)
    setModalData({ show: false, actionType: '' })
    setShowExport(false)
    setShowImport(false)
    setSaveData({ show: false, customerIds: [], mode: 'Replace', fileName: '', assets: [] })
    setRefreshImport(true)
  }

  const handleConfirmReturn = () => {
    setShowConfirm(false)
  }

  const handleSaveImport = (data: SaveModal) => {
    setSaveData(data)
  }

  useEffect(() => {
    // write why this is here
    //Have a feeling this is used to set up the initial list of equipment types.
    ;(async () => {
      const equipmentTypes: any = await getEquipmentTypes()
      setEquipmentTypes(equipmentTypes)
    })()
  }, [])

  const fetchData = async () => {

    try {
      let effectiveFilter;
  
      if (levelHelper.isNBS(auth.user?.type)) {
        // If user is of NBS type and currentCustomerFilters is empty, set filter to 'nbs'
        if (currentCustomerFilters.length === 0) {
          effectiveFilter = { customerIds: ['nbs'] };
        } else {
          effectiveFilter = { customerIds: stateMaster.currentCustomerFilters };
        }
      } else {
        // If user is not of NBS type, use customerPayload
        console.log("here!")
        effectiveFilter = { customerIds: stateMaster.customerPayload };
      }
  
   
      console.log(stateMaster)
      const results = await getAssets2({ effectiveFilter, stateMaster });
  
      setAssets(results.data.data);
     setTotalNumber(results.data.pagination.total);
    } catch (error) {
      console.error('Error fetching assets:', error);
    }
  };

//   useEffect(() => {
//     console.log("1 Ran!")
//     if(currentPage != 1)
//       {
//         setCurrentPage(1)
//       }
//       else
//       {
//         setCurrentPage(currentPage)
//        // fetchData()
//       }
    
//   //}, [perPage, slug, currentCustomerFilters, customerPayload]);
// }, [customerPayload]);

  // useEffect(() => {
  //   console.log("2 Ran!")
  //   fetchData();
  // }, [currentPage]);

  useEffect(() => {

    
    const customerData = auth.user?.customerIds;
    let customers = customerData?.map(item => ({ id: item.customerId, name: item.companyName })) || [];
  
    setStateMaster(prevState => ({
      ...prevState,
      isNBS: levelHelper.isNBS(auth.user?.type), // Change `isNBS` to true
      customerPayload: levelHelper.isNBS(auth.user?.type) ? ['nbs'] : customerIds, // Update `customerPayload`
      customerProp: customers, // Update `customerProp`
      perPage: perPage,
      slug: slug,
      currentCustomerFilters: currentCustomerFilters,
      currentPage: currentPage,
    }));
  }, [customerIds,slug,customerPayload,customerProp,perPage,currentCustomerFilters,currentPage]);

  useEffect(() => {
      fetchData()
  }, [stateMaster]);
 
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>Asset Management</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search asset'
                //onChange={(e) =>setSlug(e.target.value)}
                onChange={(e) =>setSlugSearchTerm(e.target.value)}
              />
                 <button className='btn btn-primary' onClick={handleSearch}>
      Search
    </button>
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-light-primary me-3'
                onClick={() => {
                  setShowImport(true)
                }}
              >
                <KTSVG path='/media/icons/duotone/Files/Import.svg' className='svg-icon-2' />
                Import
              </button>
              <button
                type='button'
                className='btn btn-light-primary me-3'
                onClick={() => {
                  setShowExport(true)
                }}
              >
                <KTSVG
                  path='/media/icons/duotone/Navigation/Arrow-Export.svg'
                  className='svg-icon-2'
                />
                Export
              </button>
              {isNBS && (
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  setModalData({ show: true, actionType: 'Add' })
                  setUpdateData(initialAsset)
                }}
              >
                <KTSVG path='/media/icons/duotone/Navigation/Plus.svg' className='svg-icon-2' />
                Add Asset
              </button>
              )}
              {isNBS && (
                <div className='filter-dropdown'>
                  <button
                    type='button'
                    className='btn btn-primary ms-3'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Filter options'
                    ref={filterRef}
                  >
                    <KTSVG
                      path='/media/icons/duotone/Text/Filter.svg'
                      className='svg-icon-2 text-light'
                    />
                    Filter
                  </button>
                  <FilterDropDown
                    handleFilter={handleFilter}
                    handleCancelCustomer={handleCancelCustomer}
                  />
                </div>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 service-quotes-body'>
        <DataTableBase
          columns={columns}
          data={assets}
          progressPending={loading}
          sortFunction={customAssetsSort}
          pagination
          paginationTotalRows={totalNumber}
          paginationPerPage={perPage}
          paginationDefaultPage={currentPage}
          paginationServer
          sortServer
          onChangePage={(m, n) => {
            setCurrentPage(m)
          }}
          onChangeRowsPerPage={(e, n) => {
            setPerPage(e)
          }}
        />
        </Card.Body>
      </Card>
      <ImportAssetsModal
        show={showImport}
        isNBS={isNBS}
        customerProp={customerProp}
        onHide={handleCloseModal}
        extensions={['Excel', 'CSV']}
        modes={['Replace', 'Append']}
        refresh={refreshImport}
        saveImport={handleSaveImport}
        getAssets={fetchData}//{() => getAssets({ customerIds: customerPayload })}
      />
      <SaveImportedAssetsModal
        data={saveData}
        onHide={handleCloseModal}
        userId={userId}
        getAssets={fetchData}//{() => getAssets({ customerIds: customerPayload })}
      />
      <ExportAssetsModal
        show={showExport}
        isNBS={isNBS}
        customerProp={customerProp}
        onHide={handleCloseModal}
        getAssets={fetchData}//{() => getAssets({ customerIds: customerPayload })}
        extensions={['Excel', 'CSV']}
      />
      <AssetActionModal
        modalData={modalData}
        asset={updateAsset}
        isNBS={isNBS}
        customerProp={customerProp}
        onHide={handleCloseModal}
        userId={userId}
        getAssets={fetchData}//{() => getAssets({ customerIds: customerPayload })}
        eqTypes={equipmentTypes}
      />
      {showConfirm && (
        <ConfirmModal
          content='Are you sure you would like to cancel?'
          yesText='Yes, cancel it!'
          noText='No, return'
          selectYes={handleConfirmCancel}
          selectNo={handleConfirmReturn}
        />
      )}
    </>
  )
}

const mapState = (state: RootState) => ({
  assetManagement: state.assetManagement.assets,
  loading: state.assetManagement.loading,
  userId: state.auth?.user?.id,
  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
  pagination: state.assetManagement.pagination,
})

const connector = connect(mapState, { ...assetManagementRedux.actions })
export default connector(AssetManagementPage)
