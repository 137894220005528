/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { connect, ConnectedProps } from 'react-redux'
import moment from 'moment'
import { RootState } from '../../../setup'
import { PageTitle } from '../../../_metronic/layout/core'
import { SalesStatistics, InvitedCustomers, NotificationList, NeedService } from '../../modules'
import { BlogModel, UserModel } from '../../../types'
import CustomersTable from '../customer/component/CustomersTable'
import NBSAdminTable from '../nbs-admin/component/NBSAdminTable'
//import BlogList from '../../modules/blog/BlogList'
import { CalReport } from '../../modules/cal_report/CalReport'
import DashboardApprovalQuotes from './ApprovalQuotes'
import DashboardCompletedOrders from './CompletedOrders'
import * as levelHelper from '../../../helper/level.helper'
import * as customersRedux from '../customer/redux/customerRedux'
import * as nbsAdminsRedux from '../nbs-admin/redux/nbsAdminRedux'
import * as blogsRedux from '../../modules/blog/redux/blogRedux'
import * as serviceQuoteRedux from '../service-quotes/serviceQuotesRedux'
import * as salesQuoteRedux from '../sales-quotes/salesQuotesRedux'
import * as serviceOrderRedux from '../service-orders/serviceOrdersRedux'
import * as salesOrderRedux from '../sales-orders/salesOrdersRedux'
import * as calibrationReportsRedux from '../calibration-reports/CalibrationReportsRedux'

import { getWorkOrderOverview } from './DashboardCRUD'
import { getErrorMessage } from '../../../helper'
import { lazy, Suspense } from 'react'
// Lazy load the less needed items because they are not critical to show
const BlogList = lazy(() => import('../../modules/blog/BlogList'))
const BlogForm = lazy(() => import('../../modules/blog'))
interface ReduxStateValueProps {
  isAdmin: boolean
  isNBS: boolean
  customers: UserModel[]
  serviceQuoteCount: Number
  salesQuoteCount: Number
  serviceOrderCount: Number
  salesOrderCount: Number
  calibrationReportsCount: Number
  nbsAdmins: UserModel[]
  blogs: BlogModel[]
  user: UserModel | undefined
  loadingBlogs: boolean
}
const DashboardPage: FC<ReduxStateValueProps> = ({
  isAdmin,
  isNBS,
  customers,
  serviceQuoteCount,
  salesQuoteCount,
  serviceOrderCount,
  salesOrderCount,
  calibrationReportsCount,
  user,
  nbsAdmins,
  blogs,
  loadingBlogs,
}) => {
  const [blog, setBlog] = useState<BlogModel | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  const isCustomerTechnician = levelHelper.isCustomerTechnician(user?.level)

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <SalesStatistics
            serviceQuoteCount={serviceQuoteCount}
            salesQuoteCount={salesQuoteCount}
            serviceOrderCount={serviceOrderCount}
            salesOrderCount={salesOrderCount}
            calibrationReportsCount={calibrationReportsCount}
            className='card-xl-stretch mb-xl-8'
            color='danger'
          />
        </div>
        <div className='col-xxl-8'>
        <Suspense fallback={<div>Loading...</div>}>
          <BlogList
            data={blogs}
            user={user}
            loading={loadingBlogs}
            isNBS={isNBS}
            className='card-xxl-stretch mb-xl-8 mb-5'
            handleEdit={(blog) => {
              setIsEdit(true)
              setBlog(blog)
            }}
          />
          </Suspense>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8 d-none'>
        <div className='col-xxl-8'>
          <CalReport />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {isNBS && (
        <div className='row gy-5 gx-xl-8'>
          {/* begin::Complete & Pay Quotes */}
          <div className='col-xl-12'>
            <DashboardCompletedOrders />
          </div>
          {/* end::Complete & Pay Quotes */}
          <div className='col-xl-12'>
            <CustomersTable data={customers} />
          </div>
          <div className='col-xl-12'>
            <NBSAdminTable data={nbsAdmins} />
          </div>
          {}
          <div className='col-xl-12' id='kt_blog_form_container'>
            {!isCustomerTechnician && (
              <Suspense fallback={<div>Loading...</div>}>
              <BlogForm
                isEdit={isEdit}
                values={blog}
                handleCancel={() => {
                  setIsEdit(false)
                  setBlog(null)
                }}
              />
              </Suspense>
            )}
          </div>
        </div>
      )}

      {/* end::Row */}

      {/* begin::Row */}
      {!isNBS && (
        <div className='row gy-5 g-xl-8'>
          {/* begin::Quotes Requiring your Approval */}
          <div className='col-xl-12'>
            <DashboardApprovalQuotes />
          </div>
          {/* end::Quotes Requiring your Approval */}
          <div className='col-xl-4'>
            <NeedService />
          </div>
          <div className='col-xl-4'>
            <InvitedCustomers customers={customers} admin={isAdmin} />
          </div>
          <div className='col-xl-4'>
            <NotificationList className='card-xl-stretch mb-xl-8' />
          </div>
        </div>
      )}
      {/* end::Row */}
    </>
  )
}

const mapState = (state: RootState) => ({
  customers: state.customers.customers,
  nbsAdmins: state.nbsAdmins.nbsAdmins,
  serviceQuotes: state.serviceQuotes,
  salesQuotes: state.salesQuotes,
  serviceOrders: state.serviceOrders,
  salesOrders: state.salesOrders,
  calibrationReports: state.calibrationReports.calibrationReports,
  blogs: state.blogs.blogs,
  loading: state.customers.loading,
  loadingBlogs: state.blogs.loading,
  auth: state.auth,
})
const connector = connect(mapState, {
  ...customersRedux.actions,
  ...nbsAdminsRedux.actions,
  ...blogsRedux.actions,
  ...serviceQuoteRedux.actions,
  ...salesQuoteRedux.actions,
  ...serviceOrderRedux.actions,
  ...salesOrderRedux.actions,
  ...calibrationReportsRedux.actions,
})
type PropsFromRedux = ConnectedProps<typeof connector>
type CustomerDataProps = ReduxStateValueProps & PropsFromRedux

interface IWorkOrdersOverviewResponse {
  serviceQuoteCount: Number
  salesQuoteCount: Number
  serviceOrderCount: Number
  salesOrderCount: Number
  calibrationReportsCount: Number
}

const DashboardWrapper: FC<CustomerDataProps> = (props) => {
  const isAdmin = levelHelper.isCustomerAdmin(props.auth.user?.level)
  const isNBS = levelHelper.isNBS(props.auth.user?.type)
  const name = `${props.auth.user?.firstname} ${props.auth.user?.lastname}`

  const [workOrderCounts, setWorkOrderCounts] = useState<IWorkOrdersOverviewResponse>({
    serviceQuoteCount: 0,
    salesQuoteCount: 0,
    serviceOrderCount: 0,
    salesOrderCount: 0,
    calibrationReportsCount: 0,
  })

  useEffect(() => {
    props.getBlogs({ slug: null, from: null, to: null })

    if (isNBS) {
      props.getNBSAdmins()
    }
  }, [])

  useEffect(() => {
    props.getCustomers(props.auth.activeCustomerIds)

    const workOrderConditions = {
      conditions: {
        serviceQuotes: {
          quoteNumberSlug: serviceQuotes.quoteNumberSlug ?? props.auth.user?.quotePrefix,
          quotePrefix: props.auth.user?.quotePrefix,
          customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
          status: ['Active'],
          confirm: ['Yes', 'No'],
          from: 'Invalid date',
          to: moment(new Date()).format('YYYY-MM-DD hh:mm'),
          transType: ['Cal_Lab', 'Service', 'Repair'],
        },
        salesQuotes: {
          quoteNumberSlug: salesQuotes.quoteNumberSlug ?? props.auth.user?.quotePrefix,
          quotePrefix: props.auth.user?.quotePrefix,
          customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
          status: ['Active'],
          confirm: ['Yes', 'No'],
          from: '',
          to: moment(new Date()).format('YYYY-MM-DD hh:mm'),
        },
        serviceOrders: {
          quoteNumberSlug: serviceOrders.quoteNumberSlug ?? props.auth.user?.quotePrefix,
          quotePrefix: props.auth.user?.quotePrefix,
          customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
          status: [
            'active',
            'IC',
            'inactive',
            'invoiced',
            'iqcc',
            'pupi',
            'qcc',
            'rec',
            'ship',
            'stbb',
          ],
          from: '',
          to: moment().utc().format('Y-MM-DD HH:mm'),
        },
        salesOrders: {
          quoteNumberSlug: salesOrders.quoteNumberSlug ?? props.auth.user?.quotePrefix,
          quotePrefix: props.auth.user?.quotePrefix,
          customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
          status: [
            'active',
            'IC',
            'inactive',
            'invoiced',
            'iqcc',
            'pupi',
            'qcc',
            'rec',
            'ship',
            'stbb',
          ],
          from: '',
          to: moment().utc().format('Y-MM-DD HH:mm'),
        },
        calibrationReports: {
          customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
          status: ['active'],
          from: '',
          to: moment().utc().format('Y-MM-DD HH:mm'),
        },
      },
    }

    getWorkOrderOverview(workOrderConditions)
      .then((res) => {
        setWorkOrderCounts(res.data)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })

    props.getCalibrationReports({
      customerIds: isNBS ? ['nbs'] : props.auth.activeCustomerIds,
      status: ['active'],
      from: '',
      to: moment().utc().format('Y-MM-DD HH:mm'),
    })
  }, [props.auth.activeCustomerIds])

  const pageTitle = isNBS ? `NBS Admin dashboard` : 'Customer dashboard'

  const { serviceQuotes, salesQuotes, serviceOrders, salesOrders } = props

  return (
    <>
      <PageTitle breadcrumbs={[]}>{`${pageTitle} - Logged in as ${name}`}</PageTitle>
      <DashboardPage
        isAdmin={isAdmin}
        isNBS={isNBS}
        customers={props.customers}
        serviceQuoteCount={workOrderCounts.serviceQuoteCount}
        salesQuoteCount={workOrderCounts.salesQuoteCount}
        serviceOrderCount={workOrderCounts.serviceOrderCount}
        salesOrderCount={workOrderCounts.salesOrderCount}
        calibrationReportsCount={props.calibrationReports?.length}
        nbsAdmins={props.nbsAdmins}
        user={props.auth.user}
        blogs={props.blogs}
        loadingBlogs={props.loadingBlogs}
      />
    </>
  )
}

export default connector(DashboardWrapper)
