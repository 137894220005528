import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import toast from 'react-hot-toast'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { CalibrationReportModel, fileType } from '../../../../types'
import { ConfirmModal } from '../../../components/_modal/ConfirmModal'
import { deleteAttachments } from '../../other-cal-reports/OtherCalReportsCrud'
import { AppBar, Box, Button, Checkbox, Dialog, Grid, Tooltip, Typography } from '@material-ui/core'
import { CircularProgressWithLabel } from '../../calibration-reports/component/AddCalReportModal'
import { downloadAttachment } from '../../service-quotes/serviceQuotesCrud'
import moment from 'moment'
import { getErrorMessage } from '../../../../helper'
import * as levelHelper from '../../../../helper/level.helper'
import { UserModel } from '../../../../types'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
type Props = {
  show: boolean
  onHide: any
  data: fileType[]
  fileInfo: CalibrationReportModel | undefined
  quoteId: number
  getQuotes: () => void
  isNBS?: boolean
}

const CalibrationAttachmentModal: React.FC<Props> = (props) => {
  const { show, onHide, data, quoteId, getQuotes, fileInfo } = props
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<fileType[]>(data)
  const [count, setCount] = useState<number>(0)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploading, setUploading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [all, setAll] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [isNBS, setIsNBS] = useState<boolean>(false)
  let filterData = useMemo(() => {
    return data.filter((f) => {
      return (
        f.author?.toLowerCase().includes(keyword.toLowerCase()) || f.cal_type?.toLowerCase().includes(keyword.toLowerCase()) || f.name?.toLowerCase().includes(keyword.toLowerCase())
      )
    })
  }, [keyword, data])

  const handleYesDeleteFile = useCallback(() => {
    let deletedFiles: any = []
    selectedFiles.map((item) => {
      if (item.checked) {
        deletedFiles.push({
          name: item.name,
          quoteId: item.quoteId,
          calibration_date: item.cal_date,
        })
      }
    })

    deleteAttachments(deletedFiles)
      .then(() => {
        getQuotes()
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => {})

    setShowConfirmDelete(false)
    onHide()
  }, [selectedFiles, getQuotes, setShowConfirmDelete, onHide])

  const handleDownLoad = useCallback((event: any, name: string) => {
    event.stopPropagation()

    downloadAttachment(name)
      .then((res) => {
        let url = window.URL.createObjectURL(res.data)
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.onload = () => {
          window.URL.revokeObjectURL(url) // Revoke the URL after initiating the download
        }
        a.click()
      })
      .catch(() => {
        toast.error('This file is not found.')
      })
  }, [])

  const handleDownLoadAll = useCallback(async () => {
    let downloadedFiles: any = []
    selectedFiles.map((item) => {
      if (item.checked) {
        downloadedFiles.push({ name: item.name, quoteId: item.quoteId })
      }
    })

    let count = 0
    setTotalCount(downloadedFiles.length)
    setUploading(true)
    for (const fileName of downloadedFiles) {
      if (fileName.type !== 'removed') {
        // Create a wrapper function that returns a Promise
        const downloadFile = () =>
          new Promise<void>((resolve) => {
            handleDownLoad({ stopPropagation: () => {} }, fileName.name)
            count += 1
            setUploadProgress(count)
            setTimeout(resolve, 500)
          })

        try {
          await downloadFile() // Wait for one download to initiate before starting the next
        } catch (error) {
          toast.error(`'Error downloading file ${fileName.name}: ${error}`)
          // Handle any errors
          console.error('Error downloading file:', fileName.name, error)
        }
      }
    }
    setUploading(false)
  }, [selectedFiles, handleDownLoad])

  const handleRemoveAll = () => {
    onHide()
    setSelectedFiles([])
    setShowConfirm(false)
  }
  const handleCheck = (e: any, file: fileType, index: string) => {
    let updatedFiles: fileType[] = []
    let update_count = 0
    selectedFiles.forEach((old_file, i: number) => {
      if (old_file.id === index) {
        old_file.checked = e.target.checked
      }
      if (old_file.checked) {
        update_count += 1
      }
      updatedFiles.push(old_file)
    })
    setSelectedFiles(updatedFiles)
    setCount(update_count)
  }

  const handleConfirmReturn = () => {
    setShowConfirm(false)
    setShowConfirmDelete(false)
  }
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  useEffect(() => {
    //Figure out if the user is nbs or customer
    setIsNBS(levelHelper.isNBS(user?.type))
    setSelectedFiles(data)
    setCount(0)
    setAll(false)
  }, [data])

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAll(e.target.checked)
    setSelectedFiles(
      filterData.map((f) => {
        f.checked = e.target.checked
        return f
      })
    )
    if (e.target.checked) {
      setCount(filterData.length)
    } else {
      setCount(0)
    }
  }

  const handleDelete = () => {
    setShowConfirmDelete(true)
  }

  return (
    <>
      <Modal
        size='lg'
        show={show}
        dialogClassName='w-50'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        scrollable
      >
        <Modal.Header>
          <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
            <div className='d-flex justify-content-between'>
              <h2>
                {moment(fileInfo?.dateofcal).format('MMM - YYYY')} files of {fileInfo?.customerid}
              </h2>
              <span
                className='text-hover-primary cursor-pointer'
                onClick={() => setShowConfirm(true)}
              >
                <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-6 pb-15 px-lg-17'>
          <div className='form-group'>
            <div className='dropzone dropzone-queue mb-1'>
              <div className='d-flex align-items-center position-relative mb-1'>
                <KTSVG
                  path='/media/icons/duotone/General/Search.svg'
                  className='svg-icon-1 position-absolute ms-6'
                />
                <input
                  type='text'
                  data-kt-customer-table-filter='search'
                  className='form-control form-control-solid w-250px ps-15 border-0'
                  placeholder='Search Calibration Reports'
                  value={keyword}
                  onChange={(e: any) => setKeyword(e.target.value)}
                />
              </div>
              <AppBar position='static' color='inherit' style={{ boxShadow: 'none' }}>
                {/* <Toolbar> */}
                <Grid container spacing={1} alignItems='center'>
                  <Grid item md={1}>
                    <Checkbox checked={all} onChange={handleCheckAll} />
                  </Grid>
                  <Grid item md={5}>
                    <Typography>
                      Attachment{' '}
                      <span className='text-muted'>
                        ({count} of {filterData.length} selected)
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography>Cal Type</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography>Upload Date</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography>Author</Typography>
                  </Grid>
                </Grid>
                {/* </Toolbar> */}
              </AppBar>
            </div>
            <div className='dropzone-items wm-200px'>
              <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                {filterData.map((file, index) => {
                  return (
                    file.type !== 'removed' && (
                      <Grid
                        key={file.id}
                        className='background-light-dark'
                        container
                        style={{ margin: '8px 2px' }}
                        spacing={1}
                        alignItems='center'
                      >
                          <Grid item md={1}>
                          <Typography>{index + 1}</Typography>
                            <Checkbox
                              checked={file.checked}
                              onChange={(e) => handleCheck(e, file, file.id)} />
                          </Grid>
                          <Grid item md={5}>
                            <Tooltip title={file.name}>
                              <div style={{ 
                                whiteSpace: 'normal', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                wordBreak: 'break-word' 
                              }}>
                                <Typography noWrap={false}>{file.name}</Typography>
                              </div>
                            </Tooltip>
                          </Grid>
                          <Grid item md={2}>
                            <Typography>{file.cal_type}</Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography>{moment(file.cal_date).format('MM/DD/YY')}</Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography>{file.author}</Typography>
                          </Grid>
                        </Grid>
                    )
                  )
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            {selectedFiles.length > 0 && (
              <Box className='mt-6 float-end'>
                {isNBS && (
                <Button
                  className='mx-4'
                  variant='contained'
                  disabled={count === 0}
                  onClick={handleDelete}
                  color='secondary'
                  startIcon={
                    <KTSVG
                      path='/media/icons/duotone/Files/Deleted-file.svg'
                      className='svg-icon-2 background-yellow'
                      svgClassName='background-yellow'
                    />
                  }
                >
                  delete
                </Button>
                )}
                <Button
                  variant='contained'
                  disabled={count === 0}
                  onClick={handleDownLoadAll}
                  color='primary'
                  startIcon={
                    <KTSVG
                      path='/media/icons/duotone/Files/Cloud-download.svg'
                      className='svg-icon-2'
                    />
                  }
                >
                  download
                </Button>
              </Box>
            )}
          </>
        </Modal.Footer>
      </Modal>
      {showConfirm && (
        <ConfirmModal
          content='Are you sure you would like to cancel?'
          yesText='Yes, cancel it!'
          noText='No, return'
          selectYes={handleRemoveAll}
          selectNo={handleConfirmReturn}
        />
      )}
      {showConfirmDelete && (
        <ConfirmModal
          content='Are you sure you would like to delete?'
          yesText='Yes, delete it!'
          noText='No, return'
          selectYes={handleYesDeleteFile}
          selectNo={handleConfirmReturn}
        />
      )}

      <Dialog
        open={uploading}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <CircularProgressWithLabel value={uploadProgress} count={totalCount} />
      </Dialog>
    </>
  )
}

export { CalibrationAttachmentModal }
