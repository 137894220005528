import valid from 'card-validator'

export const cardNameValidator = (cardNumber: string):any => {
    let validation = valid.cardholderName(cardNumber)
    let isValid = true
    
    if (!validation.isValid) {
        isValid = false
    }

    return { isValid }
}

export const cardNumberValidator = (cardNumber: string):any => {
    let numberValidation = valid.number(cardNumber)
    let isValid = true
    let cardType = ''
    
    if (!numberValidation.isValid) {
        isValid = false
    }

    if (numberValidation.card) {
        cardType =numberValidation.card.type
    }

    return { isValid, cardType}
}

export const cardMonthValidator = (month: string | number) => {
    let validation = valid.expirationMonth(month)
    let isValid = true
    
    if (!validation.isValid) {
        isValid = false
    }

    return { isValid }
}

export const cardYearValidator = (year: string | number) => {
    console.log(year)
    let validation = valid.expirationYear(year)
    let isValid = true
    
    if (!validation.isValid) {
        isValid = false
    }

    return { isValid }
}

export const cardCVVValidator = (cvv: string):any => {
    let validation = valid.cvv(cvv)
    let isValid = true
    
    if (!validation.isValid) {
        isValid = false
    }

    return { isValid }
}