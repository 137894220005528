import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTLeaflet } from '../../components/ContactMap'
import { KTSVG } from '../../../_metronic/helpers'
import { RootState } from '../../../setup'
import { UserModel } from '../../../types'
import { requestContactEmail } from '../../services/service'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../helper'

const ContactUsPage = () => {
  const [submit, setSubmit] = useState<boolean>(false)
  const [validate, setValidate] = useState<boolean>(true)
  const [userName, setUserName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const handleSubmit = () => {
    if (userName === '' || email === '' || subject === '' || message === '') {
      setValidate(false)
      return
    }
    setSubmit(true)

    const payload = {
      userName: userName,
      email: email,
      subject: subject,
      message: message
    }

    requestContactEmail(payload)
      .then((res) => {
        toast.success(res.data)
        setSubmit(false)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }

  useEffect(() => {
    setUserName(`${user.firstname} ${user.lastname}`)
    setEmail(user.email)

    KTLeaflet.init()
  }, [user])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Contact Us</PageTitle>
      <Card className="min-vh-100">
        <Card.Body className="p-lg-17">
          <div className='row mb-3'>
            <div className='col-md-6 pe-lg-10'>
              <h1 className='fw-bolder text-dark mb-9'>
                Send Us Email
              </h1>
              <div className='row mb-5'>
                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='fs-5 fw-bold mb-2'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='name'
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'>
                    {!validate && userName === '' && 'Name is required'}
                  </div>
                </div>
                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='fs-5 fw-bold mb-2'>Email</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='email'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'>
                    {!validate && email === '' && 'Email is required'}
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column mb-5 fv-row'>
                <label className='fs-5 fw-bold mb-2'>Subject</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='subject'
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                />
                <div className='fv-plugins-message-container invalid-feedback'>
                  {!validate && subject === '' && 'Subject is required'}
                </div>
              </div>
              <div className='d-flex flex-column mb-10 fv-row fv-plugins-icon-container'>
                <label className='fs-5 fw-bold mb-2'>Message</label>
                <textarea
                  className='form-control form-control-solid'
                  rows={6}
                  name='message'
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
                <div className='fv-plugins-message-container invalid-feedback'>
                  {!validate && message === '' && 'Message is required'}
                </div>
              </div>
              <button className='btn btn-primary' onClick={handleSubmit}>
                {
                  submit ?
                  <span>
                    Please wait... 
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span> :
                  <span className='indicator-label'>Send Feedback</span>
                }
              </button>
            </div>
            <div className='col-md-6 ps-lg-10'>
              <div id='kt_leaflet_1' className='w-100 rounded mb-2 mb-lg-0 mt-2' style={{ height: '486px' }}></div>
            </div>
          </div>
          <div className='row g-5 mb-5 mt-lg-10 mb-lg-15'>
            <div className='col-sm-6 pe-lg-10'>
              <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100'>
                <h1 className='text-dark fw-bolder my-5'>
                  Let’s Speak
                </h1>
                <div className='text-gray-700 fw-bold fs-2'>
                  1 (800) 722-5398
                </div>
              </div>
            </div>
            <div className='col-sm-6 ps-lg-10'>
              <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100'>
                <KTSVG
                  path='/media/icons/duotone/General/Location.svg'
                  className='svg-icon svg-icon-3tx svg-icon-primary'
                />
                <h1 className='text-dark fw-bolder my-5'>Our Head Office</h1>
                <div className='text-gray-700 fs-3 fw-bold'>
                  9150 Isanti St. NE Blaine, MN 55449
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default ContactUsPage