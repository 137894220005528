import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';
import { PageTitle } from '../../../_metronic/layout/core';
import { getCustomerData } from './TerritoryMapCrud';
import { getValidCustomerShippingData } from './TerritoryMapCrud';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import { array } from 'yup';
import { KTSVG } from '../../../_metronic/helpers'
import FilterDropDown from './components/MapFilter';
import MapFilter from './components/MapFilter';
import { Card } from 'react-bootstrap-v5'
import { CustomerModel } from '../../../types';
import { getNBSCustomers } from '../customers/CustomersCRUD';
import { Customer } from '../../../types';
let customerShippingData: Array<any>;

let markerArray: Array<L.Marker> = []; // Initialize as an empty array



function createRedPin(desiredWidth: number): L.Icon {
  // Descrition and review needed
  const originalWidth = 66;
  const originalHeight = 143;
  const scaleFactor = desiredWidth / originalWidth;
  const desiredHeight = Math.round(originalHeight * scaleFactor);

  const pin = L.icon({
    iconUrl: '/media/logos/pin.png',
    iconSize: [desiredWidth, desiredHeight],
    iconAnchor: [desiredWidth >> 1, desiredHeight], //bitshift of 1 
    popupAnchor: [-3, -76]
  });
  return pin;
}
function bindPopupOnClick(marker: L.Marker) {
  // Descrition and review needed
  const markerIndex = markerArray.indexOf(marker)
  let location: any[] = customerShippingData[markerIndex]
  let customerPopupInfo: string = ""

  if (location != undefined) {
    (location).forEach((customer: any) => {
      let customerJson = JSON.parse(customer)

      if (customerJson.ShipAddressUpdated != undefined) {
        customerPopupInfo += ("<div style=\"text-align: left;\">" + "<b>" + customerJson.CompanyName + "</b>"
          + " <br />Shipping Address: " + customerJson.ShipAddres
          + " <br />Ship City: " + customerJson.ShipCity
          + " <br />Ship State: " + customerJson.ShipState
          + " <br />Ship Zip: " + customerJson.ShipZip
          + " <br />Ship Latitude: " + customerJson.ShipLatitude
          + " <br />Ship Longitude: " + customerJson.ShipLongitude
          + " <br />Ship shipAddressUpdated: " + customerJson.ShipAddressUpdated
          + " <br /><br />")
      }
    });
  }
  return customerPopupInfo;
}

const TerritoryMapPage: React.FC = () => {
  const [map, setMap] = useState<L.Map | null>(null);
  const [slug, setSlug] = useState<string>('');
  const [customerIds, setCustomerIds] = useState<string[]>()
  const [filterVersion, setFilterVersion] = useState(0); // Holds the version of the filter
  const [currentFilters, setCurrentFilters] = useState({});
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [customers, setCustomers] = useState<CustomerModel[]>([])
  const [dropDownCustomers, setDropDownCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [dropDownStartDate, setDropDownStartDate] = useState<any>('')
  const [dropDownEndDate, setDropDownEndDate] = useState<any>()
  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  // Moved fetchCustomerData outside useEffect
  const fetchCustomerData = async (filters: any) => {
    try {
        const response = await getValidCustomerShippingData(filters); // 2 getting passed to crud
        customerShippingData = response.data.customerDetails;

        if (typeof customerShippingData === 'string') {
            console.log("There are no valid pin locations");
            return;
        }

        // // Clear existing markers from the map
        if(markerArray && markerArray.length > 0) {
        markerArray.forEach(marker => {
            if (map && marker) {
                marker.removeFrom(map);  // Ensure to remove marker from the map
            }
        });
       markerArray = [];  // Clear the array after removing markers
        }

        console.log(customerShippingData)
        customerShippingData.forEach((location: any) => {
          console.log(location)
            if (location != undefined && location.ShipAddressUpdated) {
                const locationData = location;
                const pin = createRedPin(15);
                const latitude = parseFloat(locationData.ShipLatitude);
                const longitude = parseFloat(locationData.ShipLongitude);
                const marker = L.marker([latitude, longitude], { icon: pin });

                marker.on('click', () => {
                    if (marker.getPopup()) {
                        marker.unbindPopup();
                    }
                    marker.bindPopup(bindPopupOnClick(marker)).openPopup();
                });
                console.log(map)
                if (map) { // Ensure the map is initialized
                  console.log("inits")
                    marker.addTo(map);
                    markerArray.push(marker);  // Track the marker for later removal
                }
            }
        });
    } catch (error) {
        console.error('Error fetching customer data:', error);
    }
};



  useEffect(() => {
    if (map === null) {
      console.log("in222")
      return;
    }

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);
    
    // Call fetchCustomerData here inside useEffect
    fetchCustomerData(currentFilters);
  }, [map, filterVersion,currentFilters]);


  // Adjust handleFilter to use async-await
  const handleFilter = async (dropdownFilters: any, fromSearch: boolean) => {
    if (!fromSearch) {
      // Directly using dropdownFilters to set currentFilters
      const newFilters = { // 1. Filters is getting created here
        searchBar: slug,
        startDate: dropdownFilters.startDate,
        endDate: dropdownFilters.endDate,
        selectedCustomers: dropdownFilters.selectedCustomers
      };
      setDropDownCustomers(dropdownFilters.selectedCustomers)
      setDropDownEndDate(dropdownFilters.endDate)
      setDropDownStartDate(dropdownFilters.startDate)
      setCurrentFilters(newFilters);
    } else {
      // If fromSearch is true, use existing dropdown states
      const filters = {
        searchBar: slug,
        startDate: dropDownStartDate,
        endDate: dropDownEndDate,
        selectedCustomers: dropDownCustomers
      };
      
      setCurrentFilters(filters);
    }
  
    // Assuming fetchCustomerData uses currentFilters, ensure it uses the latest state
    // You might need to use useEffect to call this based on changes to currentFilters
  //  await fetchCustomerData(currentFilters);
  
    // Additional actions after data fetch can be placed here
  }

  // useEffect(() => {
  //   // Filters use effect
  //   fetchCustomerData(currentFilters);
  // }, [currentFilters]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Territory Map</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search'
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
               <button className='btn btn-sm btn-primary' style={{ marginLeft: '10px' }} onClick={() => handleFilter({}, true)}>Search</button>
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>

              <div className='filter-dropdown ms-3'>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <MapFilter
                  handleCancelCustomer={handleCancelCustomer}
                  handleFilter={handleFilter}
                />

              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 service-quotes-body'>
          <div>
            <MapContainer
              id="map"
              center={[37.8, -96]}
              zoom={4}
              style={{ height: '1000px' }}
              whenCreated={setMap}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </MapContainer>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default TerritoryMapPage;